<template>
  <div class="OfferProposal">
    <!--    <div style="float: right; margin-right: 10px">-->
    <!--      <el-button-->
    <!--        type="primary"-->
    <!--        @click="submit"-->
    <!--        v-if="position !== 'offer' && !statusDisable"-->
    <!--        >保存</el-button-->
    <!--      >-->
    <!--    </div>-->
    <descriptions :descriptionData="OfferProposalData" />

    <el-descriptions
      title="补充信息"
      style="margin-bottom: -5px"
      v-if="position === 'quto'"
    />
    <el-form
      :inline="true"
      v-if="position === 'quto'"
      label-position="top"
      :model="formData"
      :rules="rules"
      :disabled="statusDisable"
      ref="formInlineRef"
      style="margin-bottom: 20px"
      class="demo-form-inline"
    >
      <!-- <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Lion ID Of Vega LM">
              <el-input
                v-model="formData.lionIdOfVegaLm"
                placeholder="Lion ID Of Vega LM"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Name of Vega LM">
              <el-input
                v-model="formData.nameOfVegaLm"
                placeholder="Name of Vega LM"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Lion Id Of CS LM">
              <el-input
                v-model="formData.lionIdOfCslm"
                placeholder="Lion Id Of CS LM"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Name of CS LM">
              <el-input
                v-model="formData.nameOfCslm"
                placeholder="Name of CS LM"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row> -->
      <el-row :gutter="24">
        <!-- <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Lion ID Of People Plus LM">
              <el-input
                v-model="formData.lionIdOfPeoplePluslm"
                placeholder="Lion ID Of People Plus LM"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Name of People Plus LM">
              <el-input
                v-model="formData.nameOfPeoplePlusLm"
                placeholder="Name of People Plus LM"
              ></el-input>
            </el-form-item></div
        ></el-col> -->
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Lion ID Of Performance LM">
              <el-input
                v-model="formData.lionIdOfPerformanceLm"
                placeholder="Lion ID Of Performance LM"
                readonly
                suffix-icon="el-icon-search"
                @click.native="selectPerson('performance')"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Name of Performance LM">
              <el-input
                v-model="formData.nameOfPerformanceLm"
                placeholder="Name of Performance LM"
                readonly
                suffix-icon="el-icon-search"
                @click.native="selectPerson('performance')"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HR Manager">
              <el-input
                v-model="formData.hrManager"
                placeholder="HR Manager"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HR Manager LLID">
              <el-input
                v-model="formData.hrManagerLlid"
                placeholder="HR Manager LLID"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <!--        <el-col :span="6"-->
        <!--          ><div class="grid-content bg-purple">-->
        <!--            <el-form-item label="Supervisor Email" prop="supervisorEmail">-->
        <!--              <el-input-->
        <!--                v-model="formData.supervisorEmail"-->
        <!--                placeholder="Supervisor Email"-->
        <!--              ></el-input>-->
        <!--            </el-form-item></div-->
        <!--        ></el-col>-->
        <!--        <el-col :span="6"-->
        <!--          ><div class="grid-content bg-purple">-->
        <!--            <el-form-item label="Supervisor Employee ID">-->
        <!--              <el-input-->
        <!--                v-model="formData.supervisorEmployeeId"-->
        <!--                placeholder="Supervisor Employee ID"-->
        <!--              ></el-input>-->
        <!--            </el-form-item></div-->
        <!--        ></el-col>-->
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
    </el-form>
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onPersonConfirm"
    />
  </div>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import {
  OfferProposalData,
  financialData, financialDataRejoin, OfferProposalDataRejoin
} from "@/views/quotaManagement/components/OfferProposalData";
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'

import { OfferProposal, saveOfferProposa } from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'OfferProposal',
  components: {
    descriptions,
    personnelSelectionDialog
  },
  props: {
    id: {
      type: String
    },
  },
  data() {
    return {
      formData: {
        lionIdOfVegaLm: '',
        nameOfVegaLm: '',
        lionIdOfCslm: '',
        nameOfCslm: '',
        lionIdOfPeoplePluslm: '',
        supervisorEmail: '',
        supervisorEmployeeId: '',
        nameOfPeoplePlusLm: '',
        hrManager: '',
        hrManagerLlid: '',
        lionIdOfPerformanceLm: '',
        nameOfPerformanceLm: '',
        id: ''
      },
      rules: {
        supervisorEmail: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur']
          }
        ]
      },
      position: 'quto',
      OfferProposalData,
    }
  },
  computed: {
    statusDisable() {
      return this.status === 'chat'
    }
  },
  created() {
    let status = this.$route.query.status
    if(status){
      this.status = status
    }
    // if(this.params.status){
    //   this.status =
    // }else {
    //   let status =
    //   if (status) {
    //     this.status = status
    //   }
    // }
  },
  mounted() {
    let position = this.$route.query.position
    if(position){
      this.position = position
    }
    if (this.position === 'offer') {
      this.OfferProposalData = financialData
    }
    this.getData()
  },
  methods: {
    async getData(){
      const data = {
        id: this.$route.query.id || this.id
      }
      let loadingInstance = this.$loading({ fullscreen: true,background:"#FFF" })
      await OfferProposal(data).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            Object.keys(this.formData).forEach((item) => {
              console.log(item == 'lionIdOfPerformanceLm', res.data[item])
              this.formData[item] = res.data[item]
            })
            console.log('**********',this.formData, res.data['lionIdOfPerformanceLm'] , res.data.nameOfPerformanceLm)
            if (this.position !== 'offer') {
              if(res.data.rejoinEmployee === '是'){
                this.OfferProposalData = OfferProposalDataRejoin
              }else {
                this.OfferProposalData = OfferProposalData
              }
            }
            if(res.data.expat == 'Expat'){
              if(this.OfferProposalData[1].content[0].key !== 'annualPackage'){
                this.OfferProposalData[1].content.unshift({
                  label: 'Annual Package（Expat）',
                  key: 'annualPackage',
                  value: '',
                  span:3
                })
              }
            }else {
              if(this.OfferProposalData[1].content[0].key === 'annualPackage'){
                this.OfferProposalData[1].content.splice(0,1)
              }
            }
            mergeDesDataOfPort(this.OfferProposalData, res.data)
            this.$nextTick(()=>{
              loadingInstance.close()
            })
          }
        }
      })
    },
    submit() {
      const data = {
        id: this.formData.id,
        ...this.formData
      }
      this.$refs.formInlineRef.validate((valid) => {
        if (valid) {
          saveOfferProposa(data).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('保存成功')
            }
          })
        }
      })
    },
    selectPerson(val) {
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        radio: true
      })
    },
    onPersonConfirm(val) {
      console.log('确认返回值', val)
      this.formData.lionIdOfPerformanceLm = val[0].lineManagerLiid;
      this.formData.nameOfPerformanceLm = val[0].englishName || val[0].userName;
      val[0].lineManagerBaseId !== undefined ? (this.formData.lineManagerBaseId = val[0].lineManagerBaseId) : ''
      val[0].lineManagerPerformanceBaseId !== undefined ? (this.formData.lineManagerPerformanceBaseId = val[0].lineManagerPerformanceBaseId) : ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
