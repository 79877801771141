export const OfferProposalData = [
  {
    title: '基础信息',
    content: [
      {
        label: 'Candidate Name',
        key: 'candidateName',
        value: ''
      },
      {
        label: 'Candidate English Name',
        key: 'candidateEnglishName',
        value: ''
      },
      {
        label: 'Candidate Email',
        key: 'candidateEmail',
        value: ''
      },
      {
        label: 'Candidate Phone',
        key: 'candidatePhone',
        value: ''
      },
      {
        label: 'Country',
        key: 'country',
        value: ''
      },
      {
        label: 'Expat',
        key: 'expat',
        value: ''
      },
      {
        label: 'Proposed Onboard Date',
        key: 'proposedOnboardDate',
        value: ''
      },
      {
        label: 'HRBP',
        key: 'hrbp',
        value: ''
      },
      {
        label: 'Team',
        key: 'team',
        value: ''
      },
      {
        label: 'Job Grading',
        key: 'jobGrading',
        value: ''
      },
      {
        label: 'Working Location',
        key: 'workingLocation',
        value: ''
      },
      {
        label: 'Office Location',
        key: 'officeLocation',
        value: ''
      },
      {
        label: 'Rejoin Employee',
        key: 'rejoinEmployee',
        value: ''
      },
      {
        label: 'Recruitment Channel',
        key: 'recruitmentChannel',
        value: ''
      },
      {
        label: 'Recruitment Channel Remark',
        key: 'recruitmentChannelRemark',
        value: ''
      },
      {
        label: 'ID Type',
        key: 'idType',
        value: ''
      },
      {
        label: 'ID Number',
        key: 'idNumber',
        value: ''
      },
      {
        label: 'Fresh/Non-Fresh Graduate',
        key: 'freshGraduate',
        value: ''
      },
      {
        label: 'Lion Id of LM',
        key: 'lineManagerLiid',
        value: ''
      },
      {
        label: 'Name of LM',
        key: 'lineManagerName',
        value: ''
      },
      {
        label: 'Candidate\'s Previous Company Category',
        key: 'candidatePreviousCompanyCategory',
        value: ''
      },
      {
        label: 'Candidate\'s Previous Company',
        key: 'candidatePreviousCompany',
        value: ''
      }
    ]
  },
  {
    title: '薪资和年假',
    content: [
      {
        label: 'Offered Monthly Salary (during probation)',
        key: 'offeredMonthlySalaryDuringProbtion',
        value: ''
      },
      {
        label: 'Offered Monthly Salary (after probation)',
        key: 'offeredMonthlySalaryAfterProbtion',
        value: ''
      },
      {
        label: 'Annual Leave Days',
        key: 'annualLeaveDays',
        value: ''
      },
      {
        label: 'Work Schedules',
        key: 'workSchedules',
        value: ''
      },
      // {
      //   label: 'Offered Monthly Salary(during Assessment)',
      //   key: 'offeredMonthlySalaryDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Offered Monthly Salary(after Assessment)',
      //   key: 'offeredMonthlySalaryAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Offered 13th Salary',
        key: 'offered13thSalary',
        value: ''
      },
      {
        label: 'Monthly Total Allowance',
        key: 'monthlyTotalAllowance',
        value: ''
      },
      {
        label: 'Bonus Eligible (Y/N)',
        key: 'bonusEligible',
        value: ''
      },
      {
        label: 'Currency',
        key: 'currency',
        value: ''
      },
      {
        label: 'Discretionary Bonus Plan',
        key: 'discretionaryBonusPlan',
        value: ''
      },
      {
        label: 'Discretionary Target Bonus %',
        key: 'discretionaryTargetBonus',
        value: ''
      },
      {
        label: 'Pay Frequency',
        key: 'payFrequency',
        value: ''
      },
      {
        label: 'Special Pool Bonus Plan',
        key: 'specialPoolBonusPlan',
        value: ''
      }
    ]
  },
  {
    title: '合同信息',
    content: [
      {
        label: 'Contract Period',
        key: 'contractPeriod',
        value: ''
      },
      {
        label: 'Notice Period',
        key: 'noticePeriod',
        value: ''
      },
      {
        label: 'Probation Period (Monthly)',
        key: 'probationPeriodMonthly',
        value: ''
      },
      {
        label: 'Notice Period (within probation)',
        key: 'noticePeriodWithinProbation',
        value: ''
      },
      {
        label: 'Title CN(during probation)',
        key: 'titleCnDuringProbation',
        value: ''
      },
      {
        label: 'Title EN(during probation)',
        key: 'titleEnDuringProbation',
        value: ''
      },
      {
        label: 'Title CN(after probation)',
        key: 'titleCnAfterProbation',
        value: ''
      },
      {
        label: 'Title EN(after probation)',
        key: 'titleEnAfterProbation',
        value: ''
      },
      // {
      //   label: 'Assessment Period (Monthly)',
      //   key: 'assessmentPeriodMonthly',
      //   value: ''
      // },
      // {
      //   label: 'Notice Period(within assessment)',
      //   key: 'noticePeriodWithinAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(during assessment)',
      //   key: 'titleEnDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(after assessment)',
      //   key: 'titleEnAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Contractual Bonus Plan',
        key: 'contractualBonusPlan',
        value: ''
      },
      {
        label: 'Contractual Target Bonus %',
        key: 'contractualTargetBonus',
        value: ''
      },
      {
        label: 'Regular/ Temporary',
        key: 'regularTemporary',
        value: ''
      }
    ]
  },
  {
    title: '其他',
    content: [
      {
        label: 'Challenge Group',
        key: 'challengeGroup',
        value: ''
      },
      {
        label: 'Employee Type',
        key: 'employeeType',
        value: ''
      },
      {
        label: 'Benefits Eligibility Start Date',
        key: 'benefitsEligibilityStartDate',
        value: ''
      },
      {
        label: 'Original Start Date',
        key: 'originalStartDate',
        value: ''
      },
      {
        label: 'Seniority start date',
        key: 'seniorityStartDate',
        value: ''
      },
      {
        label: 'Service Date',
        key: 'serviceDate',
        value: ''
      },
      {
        label: 'Relocation',
        key: 'relocation',
        value: ''
      },
      {
        label: 'Home City',
        key: 'homeCity',
        value: ''
      },
      {
        label: 'Maximum Moving Cost',
        key: 'maximumMovingCost',
        value: ''
      },
      {
        label: 'Maximum Temporary Accommodation cost',
        key: 'maximumTemporaryAccommodationCost',
        value: ''
      },
      {
        label: 'Home Trip',
        key: 'homeTrip',
        value: ''
      },
      {
        label: 'With Spouse',
        key: 'withSpouse',
        value: ''
      },
      {
        label: 'Additional Commercial Insurance',
        key: 'additionalCommercialInsurance',
        value: ''
      },
      {
        label: 'For employee only',
        key: 'forEmployeeOnly',
        value: ''
      },
      {
        label: 'Insurance Applicant',
        key: 'insuranceApplicant',
        value: ''
      },
      {
        label: 'Insurance Relationship',
        key: 'insuranceRelationship',
        value: ''
      }
    ]
  }
]
export const OfferProposalDataRejoin = [
  {
    title: '基础信息',
    content: [
      {
        label: 'Candidate Name',
        key: 'candidateName',
        value: ''
      },
      {
        label: 'Candidate English Name',
        key: 'candidateEnglishName',
        value: ''
      },
      {
        label: 'Candidate Email',
        key: 'candidateEmail',
        value: ''
      },
      {
        label: 'Candidate Phone',
        key: 'candidatePhone',
        value: ''
      },
      {
        label: 'Country',
        key: 'country',
        value: ''
      },
      {
        label: 'Expat',
        key: 'expat',
        value: ''
      },
      {
        label: 'Proposed Onboard Date',
        key: 'proposedOnboardDate',
        value: ''
      },
      {
        label: 'HRBP',
        key: 'hrbp',
        value: ''
      },
      {
        label: 'Team',
        key: 'team',
        value: ''
      },
      {
        label: 'Job Grading',
        key: 'jobGrading',
        value: ''
      },
      {
        label: 'Working Location',
        key: 'workingLocation',
        value: ''
      },
      {
        label: 'Office Location',
        key: 'officeLocation',
        value: ''
      },
      {
        label: 'Rejoin Employee',
        key: 'rejoinEmployee',
        value: ''
      },
      {
        label: 'Recruitment Channel',
        key: 'recruitmentChannel',
        value: ''
      },
      {
        label: 'Recruitment Channel Remark',
        key: 'recruitmentChannelRemark',
        value: ''
      },
      {
        label: 'ID Type',
        key: 'idType',
        value: ''
      },
      {
        label: 'ID Number',
        key: 'idNumber',
        value: ''
      },
      {
        label: 'Fresh/Non-Fresh Graduate',
        key: 'freshGraduate',
        value: ''
      },
      {
        label: 'Candidate\'s Previous Company Category',
        key: 'candidatePreviousCompanyCategory',
        value: ''
      },
      {
        label: 'Candidate\'s Previous Company',
        key: 'candidatePreviousCompany',
        value: ''
      }
    ]
  },
  {
    title: '薪资和年假',
    content: [
      {
        label: 'Offered Monthly Salary (during assessment)',
        key: 'offeredMonthlySalaryDuringProbtion',
        value: ''
      },
      {
        label: 'Offered Monthly Salary (after assessment)',
        key: 'offeredMonthlySalaryAfterProbtion',
        value: ''
      },
      {
        label: 'Annual Leave Days',
        key: 'annualLeaveDays',
        value: ''
      },
      {
        label: 'Work Schedules',
        key: 'workSchedules',
        value: ''
      },
      // {
      //   label: 'Offered Monthly Salary(during Assessment)',
      //   key: 'offeredMonthlySalaryDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Offered Monthly Salary(after Assessment)',
      //   key: 'offeredMonthlySalaryAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Offered 13th Salary',
        key: 'offered13thSalary',
        value: ''
      },
      {
        label: 'Monthly Total Allowance',
        key: 'monthlyTotalAllowance',
        value: ''
      },
      {
        label: 'Bonus Eligible (Y/N)',
        key: 'bonusEligible',
        value: ''
      },
      {
        label: 'Currency',
        key: 'currency',
        value: ''
      },
      {
        label: 'Discretionary Bonus Plan',
        key: 'discretionaryBonusPlan',
        value: ''
      },
      {
        label: 'Discretionary Target Bonus %',
        key: 'discretionaryTargetBonus',
        value: ''
      },
      {
        label: 'Pay Frequency',
        key: 'payFrequency',
        value: ''
      },
      {
        label: 'Special Pool Bonus Plan',
        key: 'specialPoolBonusPlan',
        value: ''
      }
    ]
  },
  {
    title: '合同信息',
    content: [
      {
        label: 'Contract Period',
        key: 'contractPeriod',
        value: ''
      },
      {
        label: 'Notice Period',
        key: 'noticePeriod',
        value: ''
      },
      {
        label: 'Assessment Period (Monthly)',
        key: 'probationPeriodMonthly',
        value: ''
      },
      {
        label: 'Notice Period (within assessment)',
        key: 'noticePeriodWithinProbation',
        value: ''
      },
      {
        label: 'Title CN(during assessment)',
        key: 'titleCnDuringProbation',
        value: ''
      },
      {
        label: 'Title EN(during assessment)',
        key: 'titleEnDuringProbation',
        value: ''
      },
      {
        label: 'Title CN(after assessment)',
        key: 'titleCnAfterProbation',
        value: ''
      },
      {
        label: 'Title EN(after assessment)',
        key: 'titleEnAfterProbation',
        value: ''
      },
      // {
      //   label: 'Assessment Period (Monthly)',
      //   key: 'assessmentPeriodMonthly',
      //   value: ''
      // },
      // {
      //   label: 'Notice Period(within assessment)',
      //   key: 'noticePeriodWithinAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(during assessment)',
      //   key: 'titleEnDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(after assessment)',
      //   key: 'titleEnAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Contractual Bonus Plan',
        key: 'contractualBonusPlan',
        value: ''
      },
      {
        label: 'Contractual Target Bonus %',
        key: 'contractualTargetBonus',
        value: ''
      },
      {
        label: 'Regular/ Temporary',
        key: 'regularTemporary',
        value: ''
      }
    ]
  },
  {
    title: '其他',
    content: [
      {
        label: 'Challenge Group',
        key: 'challengeGroup',
        value: ''
      },
      {
        label: 'Employee Type',
        key: 'employeeType',
        value: ''
      },
      {
        label: 'Benefits Eligibility Start Date',
        key: 'benefitsEligibilityStartDate',
        value: ''
      },
      {
        label: 'Original Start Date',
        key: 'originalStartDate',
        value: ''
      },
      {
        label: 'Seniority start date',
        key: 'seniorityStartDate',
        value: ''
      },
      {
        label: 'Service Date',
        key: 'serviceDate',
        value: ''
      },
      {
        label: 'Relocation',
        key: 'relocation',
        value: ''
      },
      {
        label: 'Home City',
        key: 'homeCity',
        value: ''
      },
      {
        label: 'Maximum Moving Cost',
        key: 'maximumMovingCost',
        value: ''
      },
      {
        label: 'Maximum Temporary Accommodation cost',
        key: 'maximumTemporaryAccommodationCost',
        value: ''
      },
      {
        label: 'Home Trip',
        key: 'homeTrip',
        value: ''
      },
      {
        label: 'With Spouse',
        key: 'withSpouse',
        value: ''
      },
      {
        label: 'Additional Commercial Insurance',
        key: 'additionalCommercialInsurance',
        value: ''
      },
      {
        label: 'For employee only',
        key: 'forEmployeeOnly',
        value: ''
      },
      {
        label: 'Insurance Applicant',
        key: 'insuranceApplicant',
        value: ''
      },
      {
        label: 'Insurance Relationship',
        key: 'insuranceRelationship',
        value: ''
      }
    ]
  }
]
export const financialData = [
  {
    title: '基础信息',
    content: [
      {
        label: 'Candidate Name',
        key: 'candidateName',
        value: ''
      },
      {
        label: 'Candidate English Name',
        key: 'candidateEnglishName',
        value: ''
      },
      {
        label: 'Candidate Email',
        key: 'candidateEmail',
        value: ''
      },
      {
        label: 'Candidate Phone',
        key: 'candidatePhone',
        value: ''
      },
      {
        label: 'Country',
        key: 'country',
        value: ''
      },
      {
        label: 'Expat',
        key: 'expat',
        value: ''
      },
      {
        label: 'Proposed Onboard Date',
        key: 'proposedOnboardDate',
        value: ''
      },
      {
        label: 'HRBP',
        key: 'hrbp',
        value: ''
      },
      {
        label: 'Team',
        key: 'team',
        value: ''
      },
      {
        label: 'Job Grading',
        key: 'jobGrading',
        value: ''
      },
      {
        label: 'Working Location',
        key: 'workingLocation',
        value: ''
      },
      {
        label: 'Office Location',
        key: 'officeLocation',
        value: ''
      },
      {
        label: 'Rejoin Employee',
        key: 'rejoinEmployee',
        value: ''
      },
      {
        label: 'Recruitment Channel',
        key: 'recruitmentChannel',
        value: ''
      },
      {
        label: 'Recruitment Channel Remark',
        key: 'recruitmentChannelRemark',
        value: ''
      },
      {
        label: 'ID Type',
        key: 'idType',
        value: ''
      },
      {
        label: 'ID Number',
        key: 'idNumber',
        value: ''
      },
      {
        label: 'Fresh/Non-Fresh Graduate',
        key: 'freshGraduate',
        value: ''
      }
    ]
  },
  {
    title: '薪资和年假',
    content: [
      {
        label: 'Offered Monthly Salary (during probation)',
        key: 'offeredMonthlySalaryDuringProbtion',
        value: ''
      },
      {
        label: 'Offered Monthly Salary (after probation)',
        key: 'offeredMonthlySalaryAfterProbtion',
        value: ''
      },
      {
        label: 'Annual Leave Days',
        key: 'annualLeaveDays',
        value: ''
      },
      {
        label: 'Work Schedules',
        key: 'workSchedules',
        value: ''
      },
      // {
      //   label: 'Offered Monthly Salary(during Assessment)',
      //   key: 'offeredMonthlySalaryDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Offered Monthly Salary(After Assessment)',
      //   key: 'offeredMonthlySalaryAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Offered 13th Salary',
        key: 'offered13thSalary',
        value: ''
      },
      {
        label: 'Monthly Total Allowance',
        key: 'monthlyTotalAllowance',
        value: ''
      },
      {
        label: 'Bonus Eligible (Y/N)',
        key: 'bonusEligible',
        value: ''
      },
      {
        label: 'Currency',
        key: 'currency',
        value: ''
      },
      {
        label: 'Discretionary Bonus Plan',
        key: 'discretionaryBonusPlan',
        value: ''
      },
      {
        label: 'Discretionary Target Bonus %',
        key: 'discretionaryTargetBonus',
        value: ''
      },
      {
        label: 'Pay Frequency',
        key: 'payFrequency',
        value: ''
      },
      {
        label: 'Special Pool Bonus Plan',
        key: 'specialPoolBonusPlan',
        value: ''
      }
    ]
  },
  {
    title: '合同信息',
    content: [
      {
        label: 'Contract Period',
        key: 'contractPeriod',
        value: ''
      },
      {
        label: 'Notice Period',
        key: 'noticePeriod',
        value: ''
      },
      {
        label: 'Probation Period (Monthly)',
        key: 'probationPeriodMonthly',
        value: ''
      },
      {
        label: 'Notice Period (within probation)',
        key: 'noticePeriodWithinProbation',
        value: ''
      },
      {
        label: 'Title CN(during probation)',
        key: 'titleCnDuringProbation',
        value: ''
      },
      {
        label: 'Title EN(during probation)',
        key: 'titleEnDuringProbation',
        value: ''
      },
      {
        label: 'Title CN(after probation)',
        key: 'titleCnAfterProbation',
        value: ''
      },
      {
        label: 'Title EN(after probation)',
        key: 'titleEnAfterProbation',
        value: ''
      },
      // {
      //   label: 'Assessment Period (Monthly)',
      //   key: 'assessmentPeriodMonthly',
      //   value: ''
      // },
      // {
      //   label: 'Notice Period(within assessment)',
      //   key: 'noticePeriodWithinAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(during assessment)',
      //   key: 'titleEnDuringAssessment',
      //   value: ''
      // },
      // {
      //   label: 'Title EN(after assessment)',
      //   key: 'titleEnAfterAssessment',
      //   value: ''
      // },
      {
        label: 'Contractual Bonus Plan',
        key: 'contractualBonusPlan',
        value: ''
      },
      {
        label: 'Contractual Target Bonus %',
        key: 'contractualTargetBonus',
        value: ''
      },
      {
        label: 'Regular/ Temporary',
        key: 'regularTemporary',
        value: ''
      }
    ]
  },
  {
    title: '其他',
    content: [
      {
        label: 'Challenge Group',
        key: 'challengeGroup',
        value: ''
      },
      {
        label: 'Employee Type',
        key: 'employeeType',
        value: ''
      },
      {
        label: 'Benefits Eligibility Start Date',
        key: 'benefitsEligibilityStartDate',
        value: ''
      },
      {
        label: 'Original Start Date',
        key: 'originalStartDate',
        value: ''
      },
      {
        label: 'Seniority start date',
        key: 'seniorityStartDate',
        value: ''
      },
      {
        label: 'Service Date',
        key: 'serviceDate',
        value: ''
      },
      {
        label: 'Relocation',
        key: 'relocation',
        value: ''
      },
      {
        label: 'Home City',
        key: 'homeCity',
        value: ''
      },
      {
        label: 'Maximum Moving Cost',
        key: 'maximumMovingCost',
        value: ''
      },
      {
        label: 'Maximum Temporary Accommodation cost',
        key: 'maximumTemporaryAccommodationCost',
        value: ''
      },
      {
        label: 'Home Trip',
        key: 'homeTrip',
        value: ''
      },
      {
        label: 'With Spouse',
        key: 'withSpouse',
        value: ''
      },
      {
        label: 'Additional Commercial Insurance',
        key: 'additionalCommercialInsurance',
        value: ''
      },
      {
        label: 'For employee only',
        key: 'forEmployeeOnly',
        value: ''
      },
      {
        label: 'Insurance Applicant',
        key: 'insuranceApplicant',
        value: ''
      },
      {
        label: 'Insurance Relationship',
        key: 'insuranceRelationship',
        value: ''
      }
    ]
  }
]
